import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import logo from "../shared/static/logo.png";
import githublogo from "../shared/static/githublogo.png";
import linkedinlogo from "../shared/static/linkedinlogo.png";
import "../shared/styles/styles.scss";
import {
    email,
    linkedin,
    linkedinShort,
    github,
    githubShort,
} from '../shared/utils/strings'

export default function ContactPage() {
    return (
        <div className="app">
            <header className="header">
                <img src={logo} className="header-logo" alt="The Kate Perry logo" />
                <div className="space--m" />
                <div className="title">
                    <Typography variant="h5">Let's Connect!</Typography>
                </div>
                <div className="body-background">
                    <div className="body">
                        <div className="space--m" />
                        <Typography variant="h6" color="textPrimary">Email me!</Typography>
                        <div className="space--s" />
                        <Link variant="subtitle1" href={"mailto:" + email}>{email}</Link>
                        <Grid container direction="row">
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                                <div className="space--l" />
                                <Divider />
                                <div className="space--l" />
                            </Grid>
                            <Grid item xs={4} />
                        </Grid>
                        <Typography variant="h6" color="textPrimary">Find me at:</Typography>
                        <div className="space--s" />
                        <Grid
                            container
                            direction="column"
                        >
                            <Grid item alignContent="flex-start">
                                <a href={github} target="_blank" rel="noopener noreferrer">
                                    <img src={githublogo} alt="Github Logo leading to Kate's Github Profile" className="text-logo" />
                                </a>
                                <Link variant="subtitle1">{githubShort}</Link>
                            </Grid>
                            <div className="space--s" />
                            <Grid item alignContent="flex-start">
                                <a href={linkedin} target="_blank" rel="noopener noreferrer">
                                    <img src={linkedinlogo} alt="LinkedIn Logo leading to Kate's LinkedIn Profile" className="text-logo" />
                                </a>
                                <Link variant="subtitle1">{linkedinShort}</Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </header>
        </div>
    );
}
