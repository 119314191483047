import React from "react";
import "../shared/styles/styles.scss";
import Typography from "@material-ui/core/Typography";
import {
  languages,
  technologies
} from '../shared/utils/strings'

export default function SkillsCard() {
    return (
      <div className="body">
        <Typography align="left" variant="h5" color="textPrimary">Languages</Typography>
        <Typography align="left" variant="subtitle1" color="textPrimary">{languages}</Typography>
        <div className="space--l" />
        <Typography align="left" variant="h5" color="textPrimary">Technologies</Typography>
        <Typography align="left" variant="subtitle1" color="textPrimary">{technologies}</Typography>
      </div>
    );
  }
