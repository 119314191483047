import React from "react";
import "../shared/styles/styles.scss";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  education
} from '../shared/utils/strings'

export default function ExperienceCard() {
  return (
    <div className="body">
      <div className="space--m" />
      <Typography align="left" variant="h5" color="textPrimary">{education.institutionName}</Typography>
      <Typography align="left" variant="h6" color="textPrimary">{education.degree}</Typography>
      <Typography align="left" variant="subtitle2" color="textPrimary">{education.dateSpan[0]} - {education.dateSpan[1]}</Typography>
      <div className="space--s" />
      <Typography align="left" variant="subtitle1" color="textPrimary">Major: <strong>{education.major}</strong></Typography>
      <Typography align="left" variant="subtitle1" color="textPrimary">Specializations: <strong>{education.specializations}</strong></Typography>
      <Typography align="left" variant="subtitle1" color="textPrimary">Minor: <strong>{education.minor}</strong></Typography>
      <div className="space--xl" />
      <Divider />
    </div>
  );
}
