import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import logo from "../shared/static/logo.png";
import history from "../shared/utils/history";
import "../shared/styles/styles.scss";
import {
  tagline1,
  tagline2,
  aboutMe
} from '../shared/utils/strings'

export default function HomePage() {
  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="header-logo" alt="The Kate Perry logo" />
        <div className="tagline">
          <Typography variant="h5" color="primary">
            {tagline1}
          </Typography>
          <div className="space--m" />
          <Typography variant="subtitle1">{tagline2}</Typography>
        </div>
        <div className="space--xl" />
        <div className="body-background">
          <div className="body">
            <div className="space--s" />
            <Typography variant="subtitle1" color="textPrimary">
              {aboutMe}
            </Typography>
            <div className="space--l" />
            <Button size="large" variant="contained" color="primary" onClick={() => history.push("/experience")}>Find out more!</Button>
          </div>
        </div>
      </header>
    </div>
  );
}
