import React from "react";
import "../shared/styles/styles.scss";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {
  portfolio
} from '../shared/utils/strings'

export default function PortfolioCard() {
  return (
    <div className="body">
      <div className="space--m" />
      <Grid container spacing={3}>
        {
          portfolio.map((prt, i) => {
            return <Grid item xs={12}>
              <Typography align="left" variant="h5" color="textPrimary">
                {prt.projectRole}
              </Typography>
              <Grid item xs={12}>
                <Typography align="left" variant="h6" color="textPrimary">
                  <strong>{prt.projectTitle}</strong>
                </Typography>
                <Typography align="left" variant="h6" color="textPrimary">
                  <strong>Problem</strong>
                </Typography>
                <Typography align="left" variant="body1" color="textPrimary">{prt.problem}</Typography>
                <div className="space--s" />
                <Typography align="left" variant="h6" color="textPrimary">
                  <strong>Approach</strong>
                </Typography>
                <Typography align="left" variant="body1" color="textPrimary">{prt.approach}</Typography>
                <div className="space--s" />
                <Typography align="left" variant="h6" color="textPrimary">
                  <strong>Result</strong>
                </Typography>
                <Typography align="left" variant="body1" color="textPrimary">{prt.result}</Typography>
                <div className="space--m" />
                <Typography align="left" variant="h6" color="textPrimary">
                  <strong>Artifacts</strong>
                </Typography>
                {prt.artifacts ? [...prt.artifacts].map((art, i) => {
                  return (<Grid container xs={12} alignItems="flex-start">
                    <Link href={art.src} target="_blank" variant="body1">{art.title}</Link>
                    <div className="space--m" />
                  </Grid>)
                }) : null}
              </Grid>
              <div className="space--l" />
              {i < portfolio.length - 1 &&
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              }
            </Grid>
          })
        }
      </Grid >
    </div>
  );
}
