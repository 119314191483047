import React from "react";
import NavigationBar from "../features/NavigationBar";
import Routes from "../shared/utils/Routes";
import "../shared/styles/styles.scss";

export default function App() {
  return (
    <div className="background">
      <NavigationBar />
      <Routes />
    </div>
  );
}
