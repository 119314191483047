import React from "react";
import Typography from "@material-ui/core/Typography";
import EducationCard from "../features/EducationCard";
import SkillsCard from "../features/SkillsCard";
import logo from "../shared/static/logo.png";
import "../shared/styles/styles.scss";

export default function EducationPage() {
  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="header-logo" alt="The Kate Perry logo" />
        <div className="space--m" />
        <div className="title">
          <Typography variant="h5">Education & Skills</Typography>
        </div>
        <div className="body-background">
          <EducationCard />
          <SkillsCard />
        </div>
        <div className="space--xl" />
        <div className="space--xl" />
      </header>
    </div>
  );
}
