import React from "react";
import Typography from "@material-ui/core/Typography";
import PortfolioCard from "../features/PortfolioCard";
import logo from "../shared/static/logo.png";
import "../shared/styles/styles.scss";

export default function PortfolioPage() {
  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="header-logo" alt="The Kate Perry logo" />
        <div className="space--m" />
        <div className="title">
          <Typography variant="h5">UX Portfolio</Typography>
        </div>
        <div className="body-background">
          <PortfolioCard />
        </div>
        <div className="space--xl" />
        <div className="space--xl" />
      </header>
    </div>
  );
}
