import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import HomePage from "../../pages/HomePage";
import ExperiencePage from "../../pages/ExperiencePage";
import EducationPage from "../../pages/EducationPage";
import PortfolioPage from "../../pages/PortfolioPage";
import ContactPage from "../../pages/ContactPage";
import history from "./history";

interface IProps {}

interface IState {}

class Routes extends Component<IProps, IState> {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/experience" component={ExperiencePage} />
          <Route exact path="/education" component={EducationPage} />
          <Route exact path="/portfolio" component={PortfolioPage} />
          <Route exact path="/contact" component={ContactPage} />
        </Switch>
      </Router>
    );
  }
}

export default Routes;