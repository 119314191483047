import React from "react";
import "../shared/styles/styles.scss";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import smallLogo from "../shared/static/small-logo.png";
import history from "../shared/utils/history";
import { pages } from "../shared/utils/strings";

export default function NavigationBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: string) => {
    history.push(value)
    setAnchorEl(null);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={6} justify="flex-start">
            <img
              src={smallLogo}
              className="small-logo"
              alt="letter K with a pink background"
              style={{ marginLeft: '10px', marginTop: '10px'}}
            />
          </Grid>
          <Grid item xs={6} justify="flex-end">
            <Grid container justify="flex-end">
              <IconButton
                aria-label="more"
                id="menu-button"
                aria-controls={open ? 'menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{ marginRight: '10px', marginTop: '10px'}}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.value} onClick={() => handleClose(page.value)}>
                    {page.name}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
