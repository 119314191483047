import React from "react";
import "../shared/styles/styles.scss";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {
  experience
} from '../shared/utils/strings'

export default function ExperienceCard() {
  return (
    <div className="body">
      <div className="space--m" />
      <Grid container spacing={3}>
        {
          experience.map((exp, i) => {
            return <Grid item xs={12}>
              <Typography align="left" variant="h5" color="textPrimary">
                {exp.jobTitle}
              </Typography>
              <Grid item xs={12}>
                <Typography align="left" variant="h6" color="textPrimary">
                  <strong>{exp.companyName}</strong>
                </Typography>
                <Typography align="left" variant="subtitle1" color="textPrimary">{exp.location}</Typography>
                <Typography align="left" variant="subtitle2" color="textPrimary">{exp.dateSpan}</Typography>
                <div className="space--m" />
                {exp.companyDescription ? <>
                  <i>
                    <Typography align="left" variant="body1" color="textPrimary">
                      {exp.companyDescription}
                    </Typography>
                  </i>
                  <div className="space--m" />
                </> : null}
                <Typography align="left" variant="body1" color="textPrimary">
                  {exp.description}
                </Typography>
                <div className="space--m" />
              </Grid>
              <Grid item xs={12}>
                {[...exp.keyProjects].map((project, i) => {
                  return (<>
                    <Typography align="left" variant="body1" color="textPrimary">
                      <strong>
                        {project.projectName ? "Key Project: " + project.projectName + "  | Role: " + project.projectRole : null}
                      </strong>
                    </Typography>
                    <Typography align="left" variant="body1" color="textPrimary">
                      <ul>
                        {project.bulletPoints.map((bullet, i) => {
                          return <li>
                            {bullet}
                          </li>
                        })}
                      </ul>
                    </Typography>
                  </>
                  )
                })}
              </Grid>
              <div className="space--m" />
              {i < experience.length - 1 &&
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              }
            </Grid>
          })
        }
      </Grid >
    </div>
  );
}
